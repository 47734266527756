import { Injectable, ViewChild } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { Md5 } from "ts-md5";
import * as momentTimezone from 'moment-timezone';
import * as moment from 'moment';
import { CallmethodService } from './callmethod.service';
import { PopupService } from './popup.service';
import { Router } from '@angular/router';
import { environment } from "src/environments/environment";
import { DataShareService } from './data-share.service';
import * as dark_map from '../../assets/map_styles/dark.json';
import * as light_map from '../../assets/map_styles/light.json';

@Injectable({
	providedIn: 'root'
})

export class GlobalService {



	boxBuyLimit: number = 0;
	moment: any = moment;
	momentTimezone: any = momentTimezone;

	first_color: string = "#037087";
	second_color: string = "#fff";
	third_color: string = "#000";

	companyDetails: any = {
		companyLat: 22.2722292,
		companyLng: 70.7603054,
		companyLogo: '',
		companyFavIcon: '',
		companyTitle: '',
		companyId: '',
		companyName: '',
		countryCode: '',
	}

	dispatcherDetails: any = {
		dispatcherId: '',
		dispatcherName: '',
		dispatcherNumber: '',
		dispatcherEmail: '',
		dispatcherImage: ''
	}

	createBookingShowsPopup: any = {
		'show_popup_user_create_booking': true,
		'show_popup_dispatcher_create_booking': true
	}

	zoneColorInMap: any = {
		'createdOneSideZone': 'purple',
		'surgeZone': 'yellow',
		'autoCreatedZone': 'blue',
		'createdZone': 'skyblue',
		'boundryZone': 'green',
		'offerZone': 'pink',
		'areaZone': 'indigo',
	}

	audioObj: any = {
		'bookingAlert': {
			'sos': true,
			'rejectBooking': true
		},
		'driverAlert': {
			'offlineWarning': true,
			'online': true,
			'offline': true
		},
		'sticky': {
			'add': true
		},
		'chat': {
			'messageReceive': true
		},
		'unassignTab': {
			'bookingAssignRemainder': true
		},
		'createBookingNotifyAlert': {
			'dispatcherAccept': false,
			'dispatcherDecline': false
		},
		'createBookingPopupAlert': {
			"createBookingPopupNotify": true
		}
	}

	isShowAddressType: string = "address";
	findDriverSettings: any = {
		'type': 'variant',
		'variant': 5, // min - 5, max - 50
		'count': 5, // max - 200
	};

	unassignBookingAlertTime: Number = 0;
	unassignBookingAlertOffTime: Number = 0;

	isCopyAccountId: boolean = false;

	isSetDriverLimit: boolean = false;
	disable: boolean = false;
	disableScrollToBottom: boolean = false;
	disableUserChatNotification: boolean = this.getLocalStorage({ 'key': 'userChatAudio' });

	unassignBookingAlertColor: string = "";
	calculateUnAssignBookingAlertTimeBasedOn: string = "";

	currency: string = '';
	timeZone: string = '';
	dateFormat: string = '';
	distanceVariant: string = '';
	mapZoom: Number = 16;

	onlineDriverListSortBy: any = {};
	driverMarkerLabel: any = {};
	driverMarkerIcon: any = {};

	manageAlertPopup: any = {};

	pickupDelay = { 'time': 1, 'color': '#008000' };
	acceptDelay = { 'time': 0, 'color': 'blue' };
	dropDelay = { 'time': 1, 'color': '#ff0000' };
	paymentDelayTime = { 'time': 1, 'color': '#d87606' };

	surgePriceZone: any = [];

	unReadAlert: number = 0;
	unReadDriverAlert: number = 0;

	companyCurrentTime: any;

	serverTimeZone: string = '';

	activeTaxDetails: any[] = [];

	tarrif: string = 'automatic';

	appMode: number = 1; // 1 automatic 0 manual

	minimumAssignMinutes: number = 1;

	waitTime: string = "";
	rentalWaitTime: string = "";
	outStationWaitTime: string = "";
	waitTimeColor: string = ""
	waitTimeDelayColor: string = ""

	openPage: string = "";

	cars: any = [];

	mapPolylineStyles: any = {
		marker: {
			dark: "red",
			light: "red"
		},
		line: {
			dark: "white",
			light: "black"
		}
	}



	renderData: any = null;

	noInternetConnection: string = '';

	openCustomersPopup: string = 'header';

	maxTransactionAmount: Number = 0;

	OnEventShowShimmer: boolean = false;
	isFirstTwentyRecord: boolean = true;
	currentOffset: number = 0;

	lightMap: any[] = (light_map as any).default;
	darkMap: any[] = (dark_map as any).default;

	carTypeId: string = '';

	constructor(
		public Popup: PopupService,
		public Router: Router,
		public CallMethod: CallmethodService,
		public shareData: DataShareService,
	) {
		this.setTheme();
		this.setMapTheme();
		if (Object.keys(this.roleJson).length <= 0) {
			this.roleJson = this.getLocalStorage({ 'key': 'dispatcherRoleJson' })
		}
	}

	allDateFormat: any = {
		'1': 'DD/MM/YYYY hh:mm:ss a',
		'2': 'DD/MM/YYYY HH:mm:ss',
		'3': 'DD/YYYY/MM hh:mm:ss a',
		'4': 'DD/YYYY/MM HH:mm:ss',
		'5': 'MM/DD/YYYY hh:mm:ss a',
		'6': 'MM/DD/YYYY HH:mm:ss',
		'7': 'MM/YYYY/DD hh:mm:ss a',
		'8': 'MM/YYYY/DD HH:mm:ss',
		'9': 'YYYY/DD/MM hh:mm:ss a',
		'10': 'YYYY/DD/MM HH:mm:ss',
		'11': 'YYYY/MM/DD hh:mm:ss a',
		'12': 'YYYY/MM/DD HH:mm:ss',
	};

	statusFlags: any = {
		'NEW_BOOKING_CREATE': 1,
		'DRIVER_NEW_REQUEST_DISPATCHER': 2,
		'DRIVER_ACCEPT_TRIP': 3,
		'DRIVER_ARRIVED_TRIP': 4,
		'DRIVER_BEGIN_TRIP': 5,
		'DRIVER_UNAVAILABLE_DISPATCHER': 6,
		'DRIVER_CANCEL_TRIP': 7,
		'USER_CANCEL_TRIP': 8,
		'COMPLETED_BOOKING': 9,
		'FAIL_TO_FIND_NEAREST_DRIVER_DISPATCHER': 10,
		'DRIVER_ONLINE': 11,
		'DRIVER_OFFLINE': 12,
		'DISPATCHER_CANCEL_TRIP': 13,
		'DISPATCHER_EDIT_TRIP': 14,
		'DRIVER_REJECT_BOOKING': 15,
		'ASSIGN_DRIVER_REJECT': 15,
		'BOOKING_TRANSFER_TO_DRIVER': 16,
		'DISPATCHER_UPDATE_BOOKING': 17,
		'FORCEFULLY_DELIVERED': 19,
		'DRIVER_SET_CURRENT_CAR': 20,
		'DRIVER_EDIT_CURRENT_CAR': 21,
		'DRIVER_SET_PREFERENCES': 22,
		'DRIVER_ZONE_LOCATION': 23,
		'DRIVER_REPAIR': 24,
		'DRIVER_CANCEL_TO_MANUAL': 25,
		'DRIVER_CANCEL_TO_AUTOMATIC': 26,
		'DRIVER_SET_GO_HOME': 27,
		'DRIVER_SET_BACK_TO_BACK': 28,
		'BOOKING_SAVE_TO_DRAFT': 70,
		'DELETE_DRAFT_BOOKING': 72,
		'DISPATCHER_ADD_STICKY_NOTE': 60,
		'DRIVER_SLIDE_TO_COMPLETED_TRIP': 81,
		'DISPATCHER_EDIT_STICKY_NOTE': 61,
		'DISPATCHER_DELETE_STICKY_NOTE': 62,
		'BOOKING_EDIT': 91,
		'DRIVER_STOP_ARRIVED_TRIP': 41,
		'DISPATCHER_SEND_MSG': 51,
		'DISPATCHER_SEND_MESSAGE': 45,
		'DRIVER_SEND_MESSAGE': 54,
		'SYSTEM_SWITCH_OFF_DRIVER_ALERT': 46,

		'USER_SEND_MESSAGE': 52,
		'RESTAURANT_SEND_MESSAGE': 53,
		'UPDATE_BOOKING_DRAFT': 71,

		// corporate
		'SURGE_MULTIPLIER': 101,
		'DISPATCHER_UPDATE_ROLE': 102,
		'ACCEPT_CREATED_BOOKING': 114,

		'USER_SEND_MESSAGE_DISPATCHER': 301,

		// driver break
		'DRIVER_BREAK_ON': 304,
		'DRIVER_BREAK_OFF': 305,

	} as const

	roleJson: any = {}
	setRoleJson(roleJson: any) {

		if (roleJson.splitter_create_edit_draft_tabs == undefined) {
			roleJson.splitter_create_edit_draft_tabs = {
				'view': 0
			}
		}
		if (roleJson.splitter_vehicle_list == undefined) {
			roleJson.splitter_vehicle_list = {
				'view': 0
			}
		}
		if (roleJson.splitter_unassign_assign_active_tabs == undefined) {
			roleJson.splitter_unassign_assign_active_tabs = {
				'view': 0
			}
		}
		if (roleJson.splitter_zone_list == undefined) {
			roleJson.splitter_zone_list = {
				'view': 0
			}
		}
		if (roleJson.menu_dashboard == undefined) {
			roleJson.menu_dashboard = {
				'view': 0
			}
		}
		if (roleJson.menu_dashboard == undefined) {
			roleJson.menu_dashboard = {
				'view': 0
			}
		}
		if (roleJson.booking_alert == undefined) {
			roleJson.booking_alert = {
				'view': 0
			}
		}
		if (roleJson.driver_alert == undefined) {
			roleJson.driver_alert = {
				'view': 0
			}
		}
		if (roleJson.sticky == undefined) {
			roleJson.sticky = {
				"view": 0,
				"add": 0,
				"delete": 0,
				"update": 0
			}
		}
		if (roleJson.userChat == undefined) {
			roleJson.userChat = {
				'view': 0
			}
		}
		if (roleJson.chat == undefined) {
			roleJson.chat = {
				'view': 0
			}
		}
		if (roleJson.close_job == undefined) {
			roleJson.close_job = {
				'view': 0
			}
		}
		if (roleJson.search_job == undefined) {
			roleJson.search_job = {
				'view': 0
			}
		}
		if (roleJson.customer_details == undefined) {
			roleJson.customer_details = {
				'view': 0
			}
		}
		if (roleJson.create_booking == undefined) {
			roleJson.create_booking = {
				"view": 0,
				"add": 0
			}
		}
		if (roleJson.repeat_booking == undefined) {
			roleJson.repeat_booking = {
				"view": 0,
				"add": 0
			}
		}
		if (roleJson.edit_booking == undefined) {
			roleJson.edit_booking = {
				"view": 0,
				"update": 0
			}
		}
		if (roleJson.cancel_booking == undefined) {
			roleJson.cancel_booking = {
				"delete": 0
			}
		}
		if (roleJson.cancel_all_repeat_booking == undefined) {
			roleJson.cancel_all_repeat_booking = {
				"delete": 0
			}
		}
		if (roleJson.delivered_booking == undefined) {
			roleJson.delivered_booking = {
				"update": 0
			}
		}
		if (roleJson.draft_booking == undefined) {
			roleJson.draft_booking = {
				"view": 0,
				"add": 0,
				"delete": 0
			}
		}
		if (roleJson.transfer_booking == undefined) {
			roleJson.transfer_booking = {
				"update": 0
			}
		}
		if (roleJson.driver_wallet_credit == undefined) {
			roleJson.driver_wallet_credit = {
				"add": 0
			}
		}
		if (roleJson.driver_wallet_debit == undefined) {
			roleJson.driver_wallet_debit = {
				"add": 0
			}
		}
		if (roleJson.driver_off == undefined) {
			roleJson.driver_off = {
				"add": 0
			}
		}
		if (roleJson.boundry_zone == undefined) {
			roleJson.boundry_zone = {
				"view": 0
			}
		}
		if (roleJson.created_zone == undefined) {
			roleJson.created_zone = {
				"view": 0
			}
		}
		if (roleJson.created_one_side_zone == undefined) {
			roleJson.created_one_side_zone = {
				"view": 0
			}
		}
		if (roleJson.surged_zone == undefined) {
			roleJson.surged_zone = {
				"view": 0
			}
		}
		if (roleJson.offer_zone == undefined) {
			roleJson.offer_zone = {
				"view": 0
			}
		}
		if (roleJson.auto_created_zone == undefined) {
			roleJson.auto_created_zone = {
				'view': 0
			}
		}
		this.roleJson = roleJson;
		this.setLocalStorage({
			'key': 'dispatcherRoleJson',
			'value': roleJson
		});
	}

	encryptData(data: any) {
		try {
			let getEncryptVal: any = CryptoJS.AES.encrypt(JSON.stringify(data), environment.encryptSecretKey).toString();
			return getEncryptVal;
		} catch (e) {
			return undefined;
		}
	}

	decryptData(data: any) {
		try {
			const bytes = CryptoJS.AES.decrypt(data, environment.encryptSecretKey);
			if (bytes.toString()) {
				return JSON.parse(bytes.toString(CryptoJS.enc.Utf8)).value;
			}
			return data.value;
		} catch (e) {
			return undefined;
		}
	}

	setLocalStorage(opts: any) {
		let value: string = "" + this.encryptData({ 'value': opts.value });
		localStorage.setItem(Md5.hashAsciiStr(environment.baseUrl + opts.key).toString(), value);
	}

	getLocalStorage(opts: { key: any; }) {
		let key = Md5.hashAsciiStr(environment.baseUrl + opts.key).toString();
		return this.decryptData(localStorage.getItem(key));
	}

	removeLocalStorage(opts: { key: any; }) {
		let key = Md5.hashAsciiStr(environment.baseUrl + opts.key).toString();
		return localStorage.removeItem(key);
	}

	convertFormat(format: any) {
		return this.allDateFormat[format] || 'YYYY-MM-DD HH:mm:ss'
	}

	getAgoTime(time: string) {
		if (time != undefined) {
			const startTime = moment(time).format('YYYY-MM-DD HH:mm:ss');
			const endTime = momentTimezone.tz(this.timeZone).format('YYYY-MM-DD HH:mm:ss');

			if (moment(endTime).diff(startTime, 'years') > 0) {
				let labal = (moment(endTime).diff(startTime, 'years') > 1) ? "years" : "year";
				return moment(endTime).diff(startTime, 'years') + " " + labal + " ago";
			}
			else if (moment(endTime).diff(startTime, 'days') > 0) {
				let labal = (moment(endTime).diff(startTime, 'days') > 1) ? "days" : "day";
				return moment(endTime).diff(startTime, 'days') + " " + labal + " ago";
			}
			else if (moment(endTime).diff(startTime, 'hours') > 0) {
				let labal = (moment(endTime).diff(startTime, 'hours') > 1) ? "hours" : "hour";
				return moment(endTime).diff(startTime, 'hours') + " " + labal + " ago";
			}
			else if (moment(endTime).diff(startTime, 'minutes') > 0) {
				let labal = (moment(endTime).diff(startTime, 'minutes') > 1) ? "minutes" : "minute";
				return moment(endTime).diff(startTime, 'minutes') + " " + labal + " ago";
			}
			else if (moment(endTime).diff(startTime, 'seconds') > 0) {
				let labal = (moment(endTime).diff(startTime, 'seconds') > 1) ? "seconds" : "second";
				return moment(endTime).diff(startTime, 'seconds') + " " + labal + " ago";
			}
			else {
				return "----";
			}
		}
		else {
			return "----";
		}
	}

	// bufferToJson(req: any) {
	// 	let buf: any = Buffer.from(JSON.stringify(req));
	// 	let bufferOriginal = Buffer.from(JSON.parse(buf).data);
	// 	return JSON.parse(bufferOriginal.toString());
	// }

	lightThemeButtonChecked: boolean = true;
	mapStyle: any;
	setTheme(themeType = '') {
		let bodyElement = <HTMLElement>document.getElementById('setThemeBody');
		if (themeType == '') {
			themeType = this.getLocalStorage({ 'key': 'themeType' });
		}

		if (themeType == "darkTheme") {
			bodyElement.classList.remove("lightTheme");
			bodyElement.classList.add("darkTheme");
			this.lightThemeButtonChecked = false
		} else {
			bodyElement.classList.remove("darkTheme");
			bodyElement.classList.add("lightTheme");
			this.lightThemeButtonChecked = true
		}

	}

	isMapDark: boolean = false;
	setMapTheme() {
		let themeType = this.getLocalStorage({ 'key': 'mapThemeType' });
		if (themeType != undefined) {
			if (themeType == 'lightTheme') {
				this.mapStyle = this.lightMap;
				this.isMapDark = false;
			} else {
				this.mapStyle = this.darkMap;
				this.isMapDark = true;
			}
		} else {
			this.isMapDark = false;
		}
	}

	dashboard() {
		return new Promise((resolve, reject) => {
			this.surgePriceZone = [];
			this.CallMethod.dashboard({}).subscribe((result: any) => {
				if (result.status == true) {

					this.timeZone = result.timeZone;
					this.dateFormat = this.convertFormat(result.dateFormat);

					setInterval(() => {
						this.companyCurrentTime = momentTimezone.tz(this.timeZone).format(this.dateFormat);
					}, 1000);

					this.distanceVariant = (result.distanceVariant && result.distanceVariant == 0) ? 'km' : 'mile';
					this.mapZoom = parseInt(result.zoom);

					this.waitTime = result.waitTime;
					this.rentalWaitTime = result.rentalWaitTime;
					this.outStationWaitTime = result.outStationWaitTime;
					this.currency = result.currency;

					this.serverTimeZone = result.serverTimeZone;

					this.carTypeId = result.defaultCar;
					this.cars = result.cars
					this.boxBuyLimit = parseInt(result.boxBuyLimit)
					this.settingInfo.dayStartTime = result.dayStartTime;
					this.settingInfo.dayEndTime = result.dayEndTime;

					this.activeTaxDetails = result.activeTaxDetails;

					this.appMode = result.appMode;
					this.tarrif = (result.appMode == 1) ? 'automatic' : 'custom';

					this.companyDetails.companyLat = result.lat;
					this.companyDetails.companyLng = result.lng;
					this.companyDetails.companyLogo = environment.baseUrl + environment.imageUrl + environment.CompanySetting + this.getLocalStorage({ 'key': 'logo' });
					this.companyDetails.companyFavIcon = environment.baseUrl + environment.imageUrl + environment.CompanySetting + this.getLocalStorage({ 'key': 'favicon' });
					this.companyDetails.companyTitle = this.getLocalStorage({ 'key': 'pageTitle' });

					this.dispatcherDetails.dispatcherId = result.dispatcherDetails.dispatcherId;
					this.dispatcherDetails.dispatcherName = result.dispatcherDetails.dispatcherName;
					this.dispatcherDetails.dispatcherNumber = result.dispatcherDetails.dispatcherMobile;
					this.dispatcherDetails.dispatcherEmail = result.dispatcherDetails.dispatcherEmail;
					this.dispatcherDetails.dispatcherImage = environment.baseUrl + environment.imageUrl + environment.Dispatcher + result.dispatcherDetails.dispatcherImage || "assets/images/driver.svg";

					this.dispatcherDetails.isDispatcherTransaction = result.dispatcherDetails.is_transaction;
					this.companyDetails.countryCode = result.countryCode;

					this.setSurgePriceZoneArray(result.surgePriceZone)

					// let openPage: string = "" + this.getLocalStorage({ 'key': 'openPage' });

					// console.log("boxBuyLimit:::::::",this.boxBuyLimit);
					// console.log("boxBuyLimit result:::::::",result.boxBuyLimit);
					// console.log("boxBuyLimit result:::::::",typeof(result.boxBuyLimit));


					// if (openPage && openPage == '') {
					// 	this.setLocalStorage({
					// 		'key': 'openPage',
					// 		'value': 'home'
					// 	});
					// 	this.openPage = 'home';
					// 	this.Router.navigate(['home']);
					// }
					// else {
					// 	this.openPage = openPage;
					// 	this.Router.navigate([openPage]);
					// }

					this.shareData.shareData({ function: 'GlobalDashboardCalled' })
					return resolve({ 'status': true });
				}
				else {
					if (result.statusCode == 1) {
						this.Popup.toasterMessage({
							'status': 'error',
							'title': 'Oops..',
							'message': 'Dispatcher data not found something went wrong!'
						});
					}
				}
			});
		});
	}

	setSurgePriceZoneArray(surgeArray: any = []) {
		this.surgePriceZone = [];
		this.surgePriceZone.push({
			'zoneId': '',
			'zoneName': 'Default',
			'driverList': {
				'freeDriverList': [],
				'acceptedBookingDriverList': [],
				'ontripBookingDriverList': [],
				'completedBookingDriverList': [],
			}
		});
		if (surgeArray.length > 0) {
			for (let j = 0; j < surgeArray.length; j++) {
				this.surgePriceZone.push({
					'zoneId': surgeArray[j]._id,
					'zoneName': surgeArray[j].zone_name,
					'driverList': {
						'freeDriverList': [],
						'acceptedBookingDriverList': [],
						'ontripBookingDriverList': [],
						'completedBookingDriverList': [],
					}
				});
			}
		}
		console.log('****** this.surgePriceZone ******', this.surgePriceZone);
	}

	getCompanyCurrentTime() {
		return momentTimezone.tz(this.timeZone).format(this.dateFormat);
	}
	// getShortName(fullName: any) {
	// 	// console.log(':::::::::::::::::::::::::: fullName ::::::::::::::::::::::::::',fullName);
	// 	return fullName ? fullName.split(' ').map((n: any) => n[0]).join('') : '';
	// }
	getShortName(fullName: any) {
		let shortName = fullName.split(' ').map((n: any) => n[0]).join('');
		let shorterName = '';
		if (shortName.length > 1) {
			shorterName = shortName[0].toUpperCase() + shortName[shortName.length - 1].toUpperCase();
		} else if (shortName.length === 1) {
			shorterName = shortName[0].toUpperCase();
		}
		return fullName ? shorterName : '';
	}

	removeObjectInArray(array: any = [], where: any = []) {

		let objIndex = array.findIndex(((obj: any = {}) => obj[where[0]] == where[1]));

		array.splice(objIndex, 1);
		return array;
	}

	filterByValueObj(array: any[], propertyName: string = "", value: string = "") {
		return array.filter((data) => JSON.stringify(data[propertyName]).toLowerCase().indexOf(value.toLowerCase()) !== -1);
	}

	sessionId: string = ""
	getSessionId() {
		if (this.sessionId == "") {
			this.sessionId = Math.floor(1000 + Math.random() * 9000).toString();
		}
		return this.sessionId;
	}

	settingInfo: any = {
		dayStartTime: "",
		dayEndTime: ""
	}
	getDayNight(time: string): string {
		console.log("===== time :: ", time);

		let currentTime = moment(this.getCompanyCurrentTime(), this.dateFormat).format('HH:mm:ss');

		// Added this afterwards
		if (time != "Invalid date" && time != null) {
			currentTime = moment(time).format("HH:mm:ss");
		}

		// console.log("==>new currentTime::",moment(this.getCompanyCurrentTime(),this.dateFormat).format('HH:mm:ss'));
		console.log("==>currentTime::", currentTime);
		let startTime = this.settingInfo["dayStartTime"];
		let endTime = this.settingInfo["dayEndTime"];
		console.log("==>startTime::", startTime);
		console.log("==>endTime::", endTime);

		if (currentTime >= endTime || currentTime <= startTime) {
			return "NIGHT";
		} else {
			return "DAY";
		}
	}

	/* tax calculate Start */
	taxCalculate(amount: any, taxJson: any = undefined) {
		let taxInfo = this.activeTaxDetails;
		let totaltaxAmount = 0.0;
		if (taxJson != undefined) {
			taxInfo = [];
			for (let i = 0; i < taxJson.length; i++) {
				taxInfo.push({
					companyTaxType: taxJson[i].taxType,
					companyTaxAmount: taxJson[i].taxAmount,
				});
			}
		}

		if (taxInfo && taxInfo.length > 0) {
			for (let i = 0; i < taxInfo.length; i++) {
				if (taxInfo[i].companyTaxType === 1) {
					totaltaxAmount += (amount * taxInfo[i].companyTaxAmount) / 100;
				} else {
					totaltaxAmount += taxInfo[i].companyTaxAmount;
				}
			}
		}

		return {
			totalTaxAmount: totaltaxAmount,
		};
	}
	/* tax calculate End */

	/*  get FirstCharacter start*/
	getFirstCharacter(name: string) {
		let nameArr = name.split(' ');
		if (nameArr.length >= 2) {
			let twoChar = nameArr[0].charAt(0) + nameArr[nameArr.length - 1].charAt(0);
			return twoChar.toUpperCase();
		} else {
			let firstChar = nameArr[0].charAt(0).toUpperCase();
			return firstChar;
		}
	}

	sortArrayOfObject(arr: any = [], column: any, order: any) {
		let response = false;

		let sortedArr = [];
		if (arr.length >= 2) {
			sortedArr = arr.sort((a: any, b: any) => {
				if (!a.hasOwnProperty(column) || !b.hasOwnProperty(column)) {
					return false;
				} else {
					let arrElementValueA = (typeof a[column] === 'string') ? a[column].toUpperCase() : a[column];
					let arrElementValueB = (typeof b[column] === 'string') ? b[column].toUpperCase() : b[column];

					let comparison = 0;
					if (arrElementValueA > arrElementValueB) {
						comparison = 1;
					} else if (arrElementValueA < arrElementValueB) {
						comparison = -1;
					}
					response = true;
					return ((order === 'desc') ? (comparison * -1) : comparison);
				}
			});
		}
		else {
			return arr;
		}

		if (response) {
			return sortedArr;
		} else {
			this.Popup.toasterMessage({
				'status': 'error',
				'title': "Please enter valid column name !!",
				'message': "column to sort array doesn't exist on array",
			});
			return arr;
		}
	}

	getDuration(opts: any = []) {
		if (opts.length > 0) {
			let origin_lat;
			let origin_lng;
			let destination_lat;
			let destination_lng;
			if (opts.length == 2) {
				origin_lat = parseFloat(opts[0].lat);
				origin_lng = parseFloat(opts[0].lng);
				destination_lat = opts[1].lat;
				destination_lng = opts[1].lng;
			}
			else {
				origin_lat = parseFloat(this.companyDetails.companyLat);
				origin_lng = parseFloat(this.companyDetails.companyLng);
				destination_lat = opts[0].lat;
				destination_lng = opts[0].lng;
			}

			var R = 6371e3; // R is earth’s radius

			var origin_lat_radians = this.toRadians(origin_lat);
			var destination_lat_radians = this.toRadians(destination_lat);

			var latRadians = this.toRadians(origin_lat - destination_lat);
			var lonRadians = this.toRadians(origin_lng - destination_lng);

			var a =
				Math.sin(latRadians / 2) * Math.sin(latRadians / 2) +
				Math.cos(destination_lat_radians) *
				Math.cos(origin_lat_radians) *
				Math.sin(lonRadians / 2) *
				Math.sin(lonRadians / 2);
			var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

			var d = R * c;
			var km = d / 1000;
			var extratAdd = km * 100;
			km = extratAdd / 1000 + km;
			var duration = 0;
			if (km <= 50) {
				duration = km * 2;
			} else {
				duration = km * 1;
			}

			return duration;
		}
		else {
			return false;
		}
	}
	toRadians(val: any) {
		var PI = 3.1415926535;
		return (val / 180.0) * PI;
	}



	// Gradient Color  for background

	setGradientStyle() {
		let styles = {
			// 'background':`${this.first_color}`,
			'background-image': `linear-gradient(to right, ${this.first_color} 0%, ${this.third_color} 61%,  ${this.first_color} 100%)`,
			'background-size': '200% auto'
		};
		return styles;
	}


	setCarIcons(icons: string) {
		// console.log('****** icons ******', icons);
		return icons ? environment.baseUrl + environment.imageUrl + environment.Cars + icons : "../../assets/images/noImage.png";
	}

	setDateTimeFormat = (date: any) => moment(date).format('h:mm A, Do MMM YYYY')
}
